import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "setup-vscode-visual-studio-code"
    }}>{`Setup VSCode `}{`(`}{`Visual Studio Code`}{`)`}</h1>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Download `}<a parentName="p" {...{
            "href": "https://code.visualstudio.com/"
          }}>{`vscode`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Download these extensions`}</p>
        <ol parentName="li">
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://marketplace.visualstudio.com/items?itemName=vscjava.vscode-java-pack"
            }}>{`Java Extension Pack`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://marketplace.visualstudio.com/items?itemName=donjayamanne.githistory"
            }}>{`Git History `}{`(`}{`git log`}{`)`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode"
            }}>{`Prettier`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://marketplace.visualstudio.com/items?itemName=xabikos.ReactSnippets"
            }}>{`Reactjs code snippets`}</a></li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Optional but recommended`}</p>
        <ol parentName="li">
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://marketplace.visualstudio.com/items?itemName=yzhang.markdown-all-in-one"
            }}>{`Markdown All in One`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://marketplace.visualstudio.com/items?itemName=robertohuertasm.vscode-icons"
            }}>{`vscode-icons`}</a></li>
          <li parentName="ol">{`Fira Code font for font ligatures`}<ol parentName="li">
              <li parentName="ol">{`go to `}<a parentName="li" {...{
                  "href": "https://github.com/tonsky/FiraCode#solution"
                }}>{`https://github.com/tonsky/FiraCode`}{`#`}{`solution`}</a></li>
              <li parentName="ol">{`click download`}</li>
              <li parentName="ol">{`extract the zip`}</li>
              <li parentName="ol">{`open the ttf folder`}</li>
              <li parentName="ol">{`select all the fonts then right click and select open`}</li>
            </ol></li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Settings `}{`(`}{`CMD + ,`}{`)`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{`{
    "editor.minimap.enabled": false,
    "editor.fontFamily": "Fira Code",
    "editor.fontLigatures": true,
    "editor.fontSize": 14,
    "git.confirmSync": false,
    "workbench.iconTheme": "vscode-icons",
    "editor.formatOnSave": true,
    "git.enableSmartCommit": true,
}
`}</code></pre>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      